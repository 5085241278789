<template>
  <v-card flat>
    <v-container fluid style="height: 100%">
      <!-- BODY -->
      <template v-if="!loading && !summaries.length && !page.pointsOnly">
        <v-row dense>
          <v-col cols="12" md="6" offset-md="3" lg="4" offset-lg="4">
            <v-btn block rounded color="color3 color3Text--text" large style="height: 69px" @click.stop="$refs.addEvent.addDialog = true">
              <v-icon class="mr-4">
                fas fa-volleyball-ball
              </v-icon>
              Create your first tournament
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row no-gutters>
          <v-col cols="12" class="text-right">
            <pdf-print
              :btnText="'Print Court QR Codes'"
              :name="`${page.name} Court QR Codes`"
              :organization="page"
            ></pdf-print>
            <v-btn
              color="color3"
              text x-small
              @click="getList"
            >
              refresh
            </v-btn>
            <v-btn
              color="color3"
              text x-small
              @click="coOp = !coOp"
            >
              {{coOp ? 'Hide' : 'Show'}} Co-op events
            </v-btn>
          </v-col>
        </v-row>
        <v-expansion-panels hover v-model="panels" multiple>
          <!-- ONGOING PANEL -->
          <v-expansion-panel>
            <v-expansion-panel-header color="color1 color1Text--text" class="title">
              Ongoing Events: {{runningTournaments.length}}
              <template v-slot:actions>
                <v-icon color="color1Text">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <loading-bar :value="loading" v-if="loading"></loading-bar>
            <v-expansion-panel-content>
              <tournament-list
                v-if="runningTournaments.length"
                :tournaments="runningTournaments"
                :loading="loading"
                :ads="[]"
                :sortDesc="[false]"
                :itemCountStart="4"
                :showCounts="true"
              ></tournament-list>
              <v-alert v-else-if="!loading" type="warning" :value="true" prominent class="mt-4 mb-0 black--text">
                <v-row align="center">
                  <v-col class="grow title">
                    You have no ongoing events
                  </v-col>
                </v-row>
              </v-alert>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- UPCOMING PANEL -->
          <v-expansion-panel style="border-top: 1px solid white !important">
            <v-expansion-panel-header color="color1 color1Text--text" class="title">
              Upcoming Events: {{upcomingTournaments.length}}
              <template v-slot:actions>
                <v-icon color="color1Text">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <loading-bar :value="loading" v-if="loading"></loading-bar>
            <v-expansion-panel-content>
              <tournament-list
                v-if="upcomingTournaments.length"
                :tournaments="upcomingTournaments"
                :loading="loading"
                :ads="[]"
                :sortDesc="[false]"
                :itemCountStart="12"
                :showCounts="true"
              ></tournament-list>
              <v-alert v-else-if="!loading" type="warning" :value="true" prominent class="mt-4 mb-0 black--text">
                <v-row align="center">
                  <v-col class="grow title">
                    You have no upcoming events
                  </v-col>
                  <v-col class="shrink">
                    <v-btn text @click="$refs.addEvent.addDialog = true">Add a Tournament</v-btn>
                  </v-col>
                </v-row>
              </v-alert>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- NEED RESULTS PANEL -->
          <v-expansion-panel style="border-top: 1px solid white !important">
            <v-expansion-panel-header color="color1 color1Text--text" class="title">
              Events Needing Results: {{needResults.length}}
              <template v-slot:actions>
                <v-icon color="color1Text">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <loading-bar :value="loading" v-if="loading && panels.includes(1)"></loading-bar>
            <v-expansion-panel-content>
              <tournament-list
                v-if="needResults.length"
                :tournaments="needResults"
                :loading="loading"
                :ads="[]"
                :sortDesc="[true]"
                :itemCountStart="4"
                :showCounts="true"
              ></tournament-list>
              <v-alert v-else type="success" :value="true" prominent class="mt-4 mb-0">
                You are all caught up
              </v-alert>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- PREVIOUS PANEL -->
          <v-expansion-panel style="border-top: 1px solid white !important">
            <v-expansion-panel-header color="color1 color1Text--text" class="title">
              Previous Events: {{pastTournaments.length}}
              <template v-slot:actions>
                <v-icon color="color1Text">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <loading-bar :value="loading" v-if="loading && panels.includes(2)"></loading-bar>
            <v-expansion-panel-content>
              <tournament-list
                v-if="pastTournaments.length"
                :tournaments="pastTournaments"
                :loading="loading"
                :ads="[]"
                :sortDesc="[true]"
                :itemCountStart="4"
                :showCounts="true"
              ></tournament-list>
              <v-alert v-else type="error" :value="true" prominent class="mt-4 mb-0">
                You don't have any completed events
              </v-alert>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>

    </v-container>
    <!-- ADD EVENT DIALOG -->
    <add-event-dialog :events="copyItems" ref="addEvent" :username="username">
    </add-event-dialog>
  </v-card>
</template>

<script>
import Summary from '@/classes/TournamentSummary'
import * as StatusEnum from '@/classes/TournamentStatus'
import firstBy from 'thenby'
import AddEventDialog from '@/components/Events/AddEventDialog'
import TournamentList from '@/components/Tournament/TournamentList'
import { mapGetters } from 'vuex'
const PdfPrint = () => import('@/components/QR/PdfPrintNoTournament')

export default {
  props: ['username'],
  data () {
    return {
      loading: false,
      copying: false,
      summaries: [],
      toCopy: null,
      panels: [],
      colleges: null,
      coOp: false
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'getPageInfo'
    ]),
    page () {
      return this.getPageInfo(this.username)
    },
    userIsAdmin () {
      return this.user && this.user.isPageAdmin(this.username)
    },
    pastTournaments () {
      return this.filterList(StatusEnum.PAST).filter(f => !(f.isPublic && f.statusId < 100 && f.isCompetitive))
    },
    needResults () {
      return this.filterList(StatusEnum.PAST).filter(f => f.isPublic && f.statusId < 100 && f.isCompetitive)
    },
    runningTournaments () {
      return [...this.filterList(StatusEnum.INPROCESS), ...this.filterList(StatusEnum.ONGOING)]
    },
    ongoingTournaments () {
      return this.filterList(StatusEnum.ONGOING)
    },
    upcomingTournaments () {
      var u = this.filterList(StatusEnum.UPCOMING)
      var t = [] // this.filterList(StatusEnum.INPROCESS)
      return [...u, ...t]
      // EVENT CAN"T BE IN MORE THAN 1 LIST
    },
    copyItems () {
      if (this.summaries) {
        const result = this.summaries.map(s => {
          return {
            id: s.id,
            name: `${s.name} '${s.startDate.format('YY')}`,
            timestamp: s.timestamp,
            type: s.eventType
          }
        })
        result.sort(firstBy('timestamp', { direction: -1 }))

        return result
      }
      return []
    }
  },
  methods: {
    filterList (status) {
      return this.summaries.filter(f => f.dateStatus === status)
    },
    getList () {
      this.loading = true
      const query = `active=false&organizationUserName=${this.username}&withCounts=true&coOp=${this.coOp}`
      this.$VBL.tournament.getAllSummaries(query)
        .then(r => {
          this.summaries = r.data.map(s => new Summary(s))
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  components: {
    AddEventDialog,
    TournamentList,
    PdfPrint
  },
  watch: {
    username: function (val) {
      this.getList()
    },
    'needResults.length': function (val) {
      if (val) {
        this.panels.push(2)
      }
    },
    summaries: function (val) {
      this.runningTournaments.length && this.panels.push(0)
      this.upcomingTournaments.length && this.panels.push(1)
      this.needResults.length && this.panels.push(2)
    },
    coOp: 'getList'
  },
  mounted () {
    this.getList()
  }
}
</script>

<style scoped>
  h2.lined {
    border-bottom: 1px solid grey;
    line-height: 0.1em;
  }
  h2.lined span {
    padding: 0 10px;
    background: #fff
  }

</style>
