<template>
  <div>
    <v-dialog
      v-model="addDialog"
      max-width="500px"
      transition="dialog-transition"
      scrollable
      :persistent="window > 0"
    >
      <!-- Button -->
      <template v-slot:activator="{ on: d }">
        <v-tooltip left>
          <span>Add Event</span>
          <template v-slot:activator="{ on: tt }">
            <v-btn
              v-on="{ ...d, ...tt }"
              color="color3 color3Text--text"
              fab
              bottom
              right
              fixed
            >
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
          </template>
        </v-tooltip>
      </template>
      <v-card>
        <!-- Toolbar -->
          <v-toolbar color="color2 color2Text--text">
            <v-toolbar-title>Add New {{eventType}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon text
              color="color2Text"
              @click.stop="addDialog = false"
              :disabled="copying"
            >
              <v-icon>fas fa-times</v-icon>
            </v-btn>
          </v-toolbar>
        <!-- Window -->
        <v-card-text class="pt-0">
          <v-window v-model="window" touchless>
            <!-- Event type -->
              <v-window-item :value="0">
                <v-container>
                  <v-row dense>
                    <v-col cols="12" class="title">
                      Event Type
                      <div class="body-1 mt-1">Choose the type of event you would like to create.</div>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        :items="eventTypes"
                        v-model="eventType"
                        label="Event Type"
                        color="color3"
                        item-color="color3"
                        solo
                      ></v-select>
                    </v-col>
                    <v-col class="text-center" cols="12" >
                      <v-btn color="color3 color3Text--text" @click.stop="stepTwo">Ok</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-window-item>
            <!-- Scratch or copy -->
              <v-window-item :value="1">
                <v-container>
                  <v-row dense>
                    <v-col cols="12" md="10" offset-md="1" sm="8" offset-sm="2">
                      <v-btn
                        color="color3 color3Text--text"
                        block
                        @click.stop="onNewTournamentClick"
                        :disabled="copying"
                      >
                        Start From Scratch
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class="my-2">
                    <v-col class="text-center" cols="12" md="10" offset-md="1" sm="8" offset-sm="2" >
                      <h2 class="lined"><span>Or</span></h2>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" md="10" offset-md="1" sm="8" offset-sm="2">
                      <v-autocomplete
                        :items="copyItems"
                        item-text="name"
                        return-object
                        v-model="toCopy"
                        :label="`Select a ${eventType.toLowerCase()} to copy`"
                        persistent-hint
                        solo
                        color="color3"
                        item-color="color3"
                      ></v-autocomplete>
                      <v-expand-transition>
                        <v-btn
                          v-if="toCopy"
                          color="color3 color3Text--text"
                          block
                          @click.stop="onCopyTournamentClick"
                          :loading="copying"
                        >
                          Copy {{eventType}}
                        </v-btn>
                      </v-expand-transition>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" md="10" offset-md="1" sm="8" offset-sm="2">
                    </v-col>
                  </v-row>
                </v-container>
              </v-window-item>
            <!-- New name and date -->
              <v-window-item :value="2">
                <v-container>
                  <v-row dense>
                    <v-col cols="12" v-if="toCopy">
                      Copying: {{toCopy.name}}
                    </v-col>
                    <v-col cols="12" class="body-1">
                      Please enter the name and start date for the new {{eventType.toLowerCase()}}.
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field
                        :label="`New ${eventType} Name*`"
                        v-model="name"
                        color="color3"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="this.eventType !== 'Private Lesson'">
                      <date-time-picker
                        :datetime.sync="startDate"
                        label="Start"
                        type="date"
                      ></date-time-picker>
                    </v-col>
                  </v-row>
                </v-container>
              </v-window-item>
          </v-window>
        </v-card-text>
        <v-divider v-if="window"></v-divider>
        <v-card-actions>
          <v-btn
            color="color3Text color3--text"
            small fab
            @click.stop="goBack"
            v-if="window"
          >
            <v-icon>fas fa-caret-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-fab-transition>
            <v-btn
              color="color3 color3Text--text"
              small fab
              @click.stop="createEvent"
              v-if="eventReady"
              :loading="copying"
            >
              <v-icon>fas fa-caret-right</v-icon>
            </v-btn>
          </v-fab-transition>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <!-- ADD DIALOG 2 -->
      <v-dialog
        v-model="fullscreenDialog"
        fullscreen
        transition="dialog-bottom-transition"
        :overlay="false"
        scrollable
      >
        <v-card v-if="fullscreenDialog">
          <v-toolbar color="color2 color2Text--text">
            <v-toolbar-title>New {{eventType}}</v-toolbar-title>
            <v-spacer></v-spacer>
              <v-btn
              color="color3Text color3--text"
              fab
              v-if="showSave"
              @click.stop="$refs.editor.saveTournament"
              :loading="$refs.editor.saving"
            >
              <v-icon>fas fa-save</v-icon>
            </v-btn>
            <v-btn
              color="color2Text" icon text
              @click.native="fullscreenDialog = false"
            >
              <v-icon>fas fa-times</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text :class="$vuetify.breakpoint.smAndDown ? 'pa-0' : null">
            <!-- TOURNAMENT EDITOR -->
            <tournament-editor
              v-if="fullscreenDialog"
              ref="editor"
              :inDialog="true"
              :tournamentIn="newTournament"
              :startDateIn="startDate"
              :nameIn="name"
              :eventType="eventType"
              @cancel-click="fullscreenDialog = false"
            ></tournament-editor>
          </v-card-text>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import DateTimePicker from '@/components/Utils/DateTimePicker.vue'
import Tournament from '@/classes/Tournament'
import TournamentEditor from '@/components/Tournament/Edit/TournamentEditor.vue'
import { mapGetters } from 'vuex'

export default {
  props: ['events', 'username'],
  data () {
    return {
      window: 0,
      eventType: 'Tournament',
      name: null,
      startDate: null,
      toCopy: null,
      copying: false,
      addDialog: false,
      fullscreenDialog: false,
      newTournament: null
    }
  },
  computed: {
    ...mapGetters(['user']),
    copyItems () {
      return this.events.filter(f => f.type === this.eventType)
    },
    eventReady () {
      return !!(this.name && (this.startDate || this.eventType === 'Private Lesson'))
    },
    showSave () {
      return this.fullscreenDialog && this.$refs && this.$refs.editor && !this.$refs.editor.viewOnly && this.$refs.editor.dirty
    },
    seriesParams () {
      return {
        tag: this.eventType === 'Private Lesson' ? `${this.name.replace(/ /g, '_')}` : `${this.name.replace(/ /g, '_')}_${this.startDate.replace(/-/g, '_')}`,
        query: `new=true&start=${this.startDate}&username=${this.username}`
      }
    },
    eventParams () {
      return {
        tag: `${this.username}/${this.name.replace(/ /g, '_')}`,
        query: 'new=true'
      }
    },
    eventTypes () {
      const a = ['Tournament', 'Clinic', 'Camp', 'Tryout', 'Series', 'League', 'Youth Program']
      if (this.user.vbl) {
        // a.push('Private Lesson')
        a.push(...['Evaluation Day', 'Training Session', 'Private Lesson'])
      }
      return a.sort()
    }
  },
  methods: {
    onNewTournamentClick () {
      this.toCopy = this.name = this.startDate = null
      this.window = 2
    },
    onCopyTournamentClick () {
      this.name = this.toCopy.name + ' COPY'
      this.window = 2
    },
    createEvent () {
      if (this.eventType === 'Series') {
        this.$router.push(`/series/${this.seriesParams.tag}/admin?${this.seriesParams.query}`)
        return
      }
      if (this.eventType === 'Private Lesson') {
        this.$router.push(`/event/${this.eventParams.tag}/admin?${this.eventParams.query}`)
        return
      }
      this.copying = true
      this.$nextTick(() => {
        if (this.toCopy) {
          this.copyTournament()
        } else {
          this.editTournament()
        }
      })
    },
    editTournament (tournament) {
      this.newTournament = tournament
      this.fullscreenDialog = true
      this.addDialog = false
    },
    reset () {
      this.toCopy = this.name = this.startDate = null
      this.window = 0
      this.eventType = 'Tournament'
    },
    copyTournament () {
      this.copying = true
      this.$VBL.tournament.getTournamentCopyById(this.toCopy.id)
        .then((response) => {
          this.editTournament(new Tournament(this.$VBL, response.data))
        })
        .catch((error) => {
          console.log(error.response)
        })
        .finally(() => {
          this.copying = false
        })
    },
    stepTwo () {
      this.window = this.eventType === 'Series' ? 2 : 1
    },
    goBack () {
      if (this.eventType === 'Series') {
        this.window = 1
        return
      }
      this.window--
    }
  },
  watch: {
    fullscreenDialog: function (v) {
      !v && this.reset()
    },
    addDialog: function (v) {
      if (!v) this.copying = false
    }
  },
  components: {
    DateTimePicker,
    TournamentEditor
  }
}
</script>

<style>

</style>
